import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { PrivacyComponent } from './privacy.component';

@NgModule({
    declarations: [
        PrivacyComponent
    ],
    imports: [
        BrowserModule
    ],
    entryComponents: [
        PrivacyComponent
    ],
    providers: [],
    bootstrap: [PrivacyComponent]
})
export class PrivacyModule { }
