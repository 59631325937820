import { Component, OnInit, ɵSWITCH_VIEW_CONTAINER_REF_FACTORY__POST_R3__, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material';
import { DialoguedComponent, MessageDialogType } from './dialoguedcomponent';
import { MessageDialogComponent } from './message.dialog.component';
import { ContactApiResponse } from './contactapiresponse';
import { ContactService } from './contactservice';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css']
})
export class ContactComponent extends DialoguedComponent implements OnInit, AfterViewInit {

    contactForm: FormGroup;

    constructor(
        dialog: MatDialog,
        private router: Router,
        private contactService: ContactService,
        private titleService: Title,
    ) {
        super(dialog);
    }

    ngOnInit() {
        this.titleService.setTitle('ADIVEX - Kontakt');

        this.contactForm = new FormGroup({
            firstName: new FormControl('', []),
            lastName: new FormControl('', []),
            company: new FormControl('', []),
            email: new FormControl('', [
                Validators.maxLength(255),
                this.emailValidator()
            ]),
            phone: new FormControl('', [
                Validators.pattern(new RegExp('[0-9]'))
            ]),
            message: new FormControl('', [
                Validators.maxLength(2000),
            ]),
            privacy: new FormControl(false, []),
            newsletter: new FormControl(false, []),
        });
    }

    ngAfterViewInit() {
        window.scroll(0, 0);
    }

    submit(): void {
        this.contactService.sendContactRequest(this.contactForm.value).subscribe((res: ContactApiResponse) => {
            const message = `
                Vielen Dank für Ihre Nachricht. Wir werden uns umgehend bei Ihnen melden.
            `;

            const dialogRef: MatDialogRef<MessageDialogComponent> = this.showDialogMessage('Nachricht gesendet!', message, MessageDialogType.Info);
            dialogRef.afterClosed().subscribe(() => {
                this.contactForm.reset();

                Object.keys(this.contactForm.controls).forEach(key => {
                    this.contactForm.get(key).setErrors(null);
                });

                this.router.navigate(['unternehmen']);
            });
        });
    }

    emailValidator(): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} | null => {
            const validEmail: boolean = new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i).test(control.value);
            return !validEmail ? { 'invalidEmail': { value: control.value }} : null;
        };
    }
}
