import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { TermsComponent } from './terms.component';

@NgModule({
    declarations: [
        TermsComponent
    ],
    imports: [
        BrowserModule
    ],
    entryComponents: [
        TermsComponent
    ],
    providers: [],
    bootstrap: [TermsComponent]
})
export class TermsModule { }
