import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    constructor(
        private titleService: Title
    ) {}

    ngOnInit() {
        this.titleService.setTitle('ADIVEX - Software-Excellence aus Erfahrung');
    }
}
