import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { PageNotFoundComponent } from './pagenotfound.component';

@NgModule({
    declarations: [
        PageNotFoundComponent
    ],
    imports: [
        BrowserModule,
        RouterModule,
    ],
    entryComponents: [
        PageNotFoundComponent
    ],
    providers: [],
    bootstrap: [PageNotFoundComponent]
})
export class PageNotFoundModule { }
