import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
    selector: 'header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
    @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: MouseEvent) {
        if ((window as any).scrollY >= 100) {
            this.activateBackground();
            return;
        }

        this.deactivateBackground();
    }

    constructor(
        private router: Router
    ) {}

    ngOnInit(): void {
    }

    jumpTo(section: string): void {
        if (!this.isOnMainPage()) {
            return;
        }

        const $elem: Element = document.querySelector('#' + section + '-anchor');
        $elem.scrollIntoView(true);

        const $nav: Element = document.querySelector('nav');

        const scrolledY = window.scrollY;
        if (scrolledY) {
            window.scroll({
                left: 0,
                top: scrolledY - $nav.getBoundingClientRect().height,
                behavior: 'smooth'
            });
        }
    }

    activateBackground(): void {
        const $elem: Element = document.querySelector('#navbar');
        ($elem as any).className = ($elem as any).className.replace('header-background-none', 'header-background-active');
    }

    deactivateBackground(): void {
        const $elem: Element = document.querySelector('#navbar');
        ($elem as any).className = ($elem as any).className.replace('header-background-active', 'header-background-none');
    }

    isOnMainPage(): boolean {
        return this.router.url === '/' || this.router.url === '/#beratung' || this.router.url === '/#software' || this.router.url === '/#webdesign';
    }
}