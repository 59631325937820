import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    hasLoaded: boolean = false;

    constructor(
        private router: Router
    ) {}

    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.loadScript('corejs', 'assets/js/core.js');
                this.loadScript('gmapi', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAeJ3yS5IQ3e7Z9Gsk6w0I5jLoSZozwoxI&callback=initMap');
            }
        });

        setTimeout(() => {
            this.hasLoaded = true;
            let splashscreen: any = document.querySelector('#splashscreen');
            splashscreen.className = 'fade-out';

            setTimeout(() => {
                splashscreen.parentNode.removeChild(splashscreen);
            }, 2000);
        }, 500);
    }

    loadScript(id, src) {
        if (document.getElementById(id) != null)
        document.getElementById(id).remove();

        const node = document.createElement('script');
        node.src = src;
        node.type = 'text/javascript';
        node.async = false;
        node.id = id;
        node.charset = 'utf-8';
        document.getElementsByTagName('body')[0].appendChild(node);
    }
}