import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule, MatButtonModule, MatFormFieldModule, MatDialogModule, MatCheckboxModule } from '@angular/material';


import { ContactComponent } from './contact.component';
import { MessageDialogComponent } from './message.dialog.component';

@NgModule({
    declarations: [
        ContactComponent,
        MessageDialogComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
    ],
    entryComponents: [
        ContactComponent,
        MessageDialogComponent
    ],
    providers: [],
    bootstrap: [ContactComponent]
})
export class ContactModule { }
