import { Component, OnInit, AfterViewInit, Directive } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { Title } from '@angular/platform-browser';


@Component({
    selector: 'app-root',
    templateUrl: './aboutus.component.html',
    styleUrls: ['./aboutus.component.css']
})
export class AboutUsComponent implements OnInit, AfterViewInit {
    constructor(
        private titleService: Title
    ) {}

    ngOnInit() {
        this.titleService.setTitle('ADIVEX - Unternehmen');
    }

    ngAfterViewInit() {
        window.scroll(0, 0);
    }
}
