import { Component, OnInit, AfterViewInit, Directive } from '@angular/core';
import { Router } from '@angular/router';


@Component({
    selector: 'app-root',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit, AfterViewInit {
    cancelled: boolean = false;

    constructor(
        private router: Router
    ) {}

    ngOnInit() {
        this.cancelled = this.router.url.toLocaleLowerCase().indexOf('cancel') !== -1;
    }

    ngAfterViewInit() {
        window.scroll(0, 0);
    }
}
