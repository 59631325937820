import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AboutUsComponent } from './aboutus.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        AboutUsComponent
    ],
    imports: [
        BrowserModule,
        RouterModule
    ],
    entryComponents: [
        AboutUsComponent
    ],
    providers: [],
    bootstrap: [AboutUsComponent]
})
export class AboutUsModule { }
