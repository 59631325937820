import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { LegalComponent } from './legal.component';

@NgModule({
    declarations: [
        LegalComponent
    ],
    imports: [
        BrowserModule
    ],
    entryComponents: [
        LegalComponent
    ],
    providers: [],
    bootstrap: [LegalComponent]
})
export class LegalModule { }
