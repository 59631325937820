import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

    constructor(
        private router: Router
    ) {}

    ngOnInit(): void {
    }

    jumpTo(section: string): void {
        if (!this.isOnMainPage()) {
            return;
        }

        const $elem: Element = document.querySelector('#' + section + '-anchor');
        $elem.scrollIntoView(true);

        const $nav: Element = document.querySelector('nav');

        const scrolledY = window.scrollY;
        if (scrolledY) {
            window.scroll({
                left: 0,
                top: scrolledY - $nav.getBoundingClientRect().height,
                behavior: 'smooth'
            });
        }
    }

    isOnMainPage(): boolean {
        return this.router.url === '/' || this.router.url === '/#beratung' || this.router.url === '/#software' || this.router.url === '/#webdesign';
    }
}
