import { Component, OnInit, AfterViewInit, Directive } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { Title } from '@angular/platform-browser';


@Component({
    selector: 'app-root',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit, AfterViewInit {
    constructor(
        private titleService: Title
    ) {}

    ngOnInit() {
        this.titleService.setTitle('ADIVEX - Aktuelles');
    }

    ngAfterViewInit() {
        window.scroll(0, 0);
    }

    readMore(elementIdToHide: string, elementIdToShow: string): void {
        const $elementToHide: Element = document.querySelector('#' + elementIdToHide);
        const $elementToShow: Element = document.querySelector('#' + elementIdToShow);

        $elementToHide.className = 'hide';
        $elementToShow.className = 'show';
    }
}
