import { BrowserModule } from '@angular/platform-browser';
// import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';

import { HomeComponent } from './home.component';

@NgModule({
    declarations: [
        HomeComponent
    ],
    imports: [
        BrowserModule,
        RouterModule,
        LazyLoadImageModule.forRoot({ preset: intersectionObserverPreset }),
    ],
    entryComponents: [
        HomeComponent
    ],
    providers: [],
    bootstrap: [HomeComponent]
})
export class HomeModule { }
