import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { MessageDialogComponent } from './message.dialog.component';

export class MessageDialogType {
    static Info: string = 'info';
    static Warning: string = 'warning';
    static Error: string = 'error';
}

export class DialoguedComponent {

    constructor(
        protected dialog: MatDialog,
    ) { }

    showDialogMessage(title: string, message: string, type: string = MessageDialogType.Info): MatDialogRef<MessageDialogComponent> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: title,
            message: message,
            type: type
        };

        return this.dialog.open(MessageDialogComponent, dialogConfig);
    }
}