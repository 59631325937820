import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit, AfterViewInit {
    constructor(
        private titleService: Title
    ) {}

    ngOnInit() {
        this.titleService.setTitle('ADIVEX - Software-Excellence aus Erfahrung');
    }

    ngAfterViewInit() {
        window.scroll(0, 0);
    }
}
