import { BrowserModule } from '@angular/platform-browser';
// import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';

import { CompetenciesComponent } from './competencies.component';

@NgModule({
    declarations: [
        CompetenciesComponent
    ],
    imports: [
        BrowserModule,
        RouterModule,
        LazyLoadImageModule.forRoot({ preset: intersectionObserverPreset }),
    ],
    entryComponents: [
        CompetenciesComponent
    ],
    providers: [],
    bootstrap: [CompetenciesComponent]
})
export class CompetenciesModule { }
