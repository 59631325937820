import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ReferencesComponent } from './references.component';

@NgModule({
    declarations: [
        ReferencesComponent
    ],
    imports: [
        BrowserModule
    ],
    entryComponents: [
        ReferencesComponent
    ],
    providers: [],
    bootstrap: [ReferencesComponent]
})
export class ReferencesModule { }
