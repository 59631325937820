import { Routes, ExtraOptions } from '@angular/router';

import { HomeComponent } from './modules/home/home.component';
import { ContactComponent } from './modules/contact/contact.component';
import { LegalComponent } from './modules/legal/legal.component';
import { PrivacyComponent } from './modules/privacy/privacy.component';
import { ReferencesComponent } from './modules/references/references.component';
import { AboutUsComponent } from './modules/aboutus/aboutus.component';
import { PageNotFoundComponent } from './pagenotfound/pagenotfound.component';
import { BlogComponent } from './modules/blog/blog.component';
import { LandingPageComponent } from './modules/landingpage/landingpage.component';
import { TermsComponent } from './modules/terms/terms.component';
import { NewsletterComponent } from './modules/newsletter/newsletter.component';
import { CompetenciesComponent } from './modules/competencies/competencies.component';

export const AppRoutes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'referenzen',
        component: ReferencesComponent
    },

    {
        path: 'kompetenzen',
        component: CompetenciesComponent
    },
    {
        path: 'kompetenzen/software',
        component: CompetenciesComponent
    },
    {
        path: 'kompetenzen/apps',
        component: CompetenciesComponent
    },
    {
        path: 'kompetenzen/devsecops',
        component: CompetenciesComponent
    },
    {
        path: 'kompetenzen/datenmigration',
        component: CompetenciesComponent
    },
    {
        path: 'kompetenzen/beratung',
        component: CompetenciesComponent
    },
    {
        path: 'kompetenzen/prozessoptimierung',
        component: CompetenciesComponent
    },
    {
        path: 'kompetenzen/digitalisierung',
        component: CompetenciesComponent
    },
    {
        path: 'kompetenzen/cloud',
        component: CompetenciesComponent
    },
    {
        path: 'kompetenzen/bigdata',
        component: CompetenciesComponent
    },
    {
        path: 'kompetenzen/ki',
        component: CompetenciesComponent
    },
    {
        path: 'kompetenzen/webdesign',
        component: CompetenciesComponent
    },
    {
        path: 'kompetenzen/drohnen',
        component: CompetenciesComponent
    },

    {
        path: 'unternehmen',
        component: AboutUsComponent
    },
    {
        path: 'kontakt',
        component: ContactComponent
    },
    {
        path: 'impressum',
        component: LegalComponent
    },
    {
        path: 'datenschutzerklaerung',
        component: PrivacyComponent
    },
    {
        path: 'agb',
        component: TermsComponent
    },
    // {
    //     // landing page
    //     path: 'wilkommen/:landingPageId',
    //     component: LandingPageComponent
    // },
    {
        // newsletter
        path: 'newsletter',
        component: NewsletterComponent
    },
    {
        // cancel newsletter
        path: 'cancelnewsletter',
        component: NewsletterComponent
    },
    {
        // 404 - Page not found
        path: '404',
        component: PageNotFoundComponent
    },
    {
        // Unknown pages
        path: '**',
        redirectTo: '/404'
    },
];

export const RouterOptions: ExtraOptions = {
    useHash: false,
    anchorScrolling: 'enabled',
    // scrollPositionRestoration: 'enabled'
};
