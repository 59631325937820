import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './pagenotfound.component.html',
    styleUrls: ['./pagenotfound.component.scss']
})
export class PageNotFoundComponent implements OnInit, AfterViewInit {
    constructor() {}

    ngOnInit() {
    }

    ngAfterViewInit() {
        window.scroll(0, 0);
    }
}
