import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { ContactApiResponse } from './contactapiresponse';

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    URL: string = 'https://streamline.adivex.de/api/contact';

    constructor() {
    }

    sendContactRequest(contactFormValue: any): Observable<ContactApiResponse> {
        return new Observable<ContactApiResponse>((subscriber: Subscriber<ContactApiResponse>) => {
            fetch(this.URL, {
                method: 'post',
                mode: 'no-cors',
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(contactFormValue)
            })
                .then((data) => {
                    subscriber.next({ result: true });
                    subscriber.complete();
                })
                .catch((error) => {
                    console.error(error);
                    subscriber.error({ result: false });
                    subscriber.complete();
                });
        });
    }
}
