import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutes, RouterOptions } from '../../router.config';
import { AppComponent } from './app/app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { HomeModule } from '../home/home.module';
import { ContactModule } from '../contact/contact.module';
import { LegalModule } from '../legal/legal.module';
import { PrivacyModule } from '../privacy/privacy.module';
import { ReferencesModule } from '../references/references.module';
import { CompetenciesModule } from '../competencies/competencies.module';
import { AboutUsModule } from '../aboutus/aboutus.module';
import { PageNotFoundModule } from 'src/app/pagenotfound/pagenotfound.module';
import { BlogModule } from '../blog/blog.module';
import { LandingPageModule } from '../landingpage/landingpage.module';
import { TermsModule } from '../terms/terms.module';
import { NewsletterModule } from '../newsletter/newsletter.module';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent
    ],
    imports: [
        BrowserModule,
        RouterModule.forRoot(AppRoutes, RouterOptions),
        BrowserAnimationsModule,
        HomeModule,
        ContactModule,
        LegalModule,
        TermsModule,
        PrivacyModule,
        ReferencesModule,
        CompetenciesModule,
        AboutUsModule,
        BlogModule,
        LandingPageModule,
        PageNotFoundModule,
        NewsletterModule
    ],
    entryComponents: [
        AppComponent,
    ],
    exports: [
        AppComponent,
        HeaderComponent,
        FooterComponent
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
