import { Component, OnInit, AfterViewInit, Directive, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LandingPageService } from './landingpageservice';
import { LandingPageApiResponse } from './landingpageapiresponse';
import { Title } from '@angular/platform-browser';


@Component({
    selector: 'app-root',
    templateUrl: './landingpage.component.html',
    styleUrls: ['./landingpage.component.css']
})
export class LandingPageComponent implements OnInit, AfterViewInit, OnDestroy {
    landingPageId: string = '';
    private routeParamSubscription: Subscription;

    landingPage: LandingPageApiResponse = {
        result: false
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private landingPageService: LandingPageService,
        private titleService: Title
    ) { }

    ngOnInit() {
        this.titleService.setTitle('ADIVEX - Wilkommen!');

        this.routeParamSubscription = this.route.params.subscribe(params => {
            this.landingPageId = params['landingPageId'];

            console.log(this.landingPageId);

            this.landingPageService.requestLandingPage(this.landingPageId).subscribe((res: LandingPageApiResponse) => {
                this.landingPage = res;

                setTimeout(() => {
                    // scroll to slogan or welcome-text
                    const $elem: Element = document.querySelector('#slogan');

                    const $nav: Element = document.querySelector('nav');
                    let scrolledY = window.scrollY;
                    if (!scrolledY) {
                        scrolledY = 0;
                    }

                    window.scroll({
                        left: 0,
                        top: $elem.getBoundingClientRect().top + scrolledY - $nav.getBoundingClientRect().height + 3,
                        behavior: 'smooth',
                    });

                }, 1500);
            }, (err: LandingPageApiResponse) => {
                this.router.navigate(['404']);
            });
        });
    }

    ngOnDestroy() {
        this.routeParamSubscription.unsubscribe();
    }

    ngAfterViewInit() {
        window.scroll(0, 0);
    }
}
