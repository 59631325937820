import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { LandingPageApiResponse } from './landingpageapiresponse';

@Injectable({
    providedIn: 'root'
})
export class LandingPageService {

    landingPageId: string | undefined;

    URL: string = 'https://streamline.adivex.de/api/adivexlandingpage';

    constructor() {
    }

    requestLandingPage(landingPageId: string): Observable<LandingPageApiResponse> {
        return new Observable<LandingPageApiResponse>((subscriber: Subscriber<LandingPageApiResponse>) => {
            fetch(this.URL, {
                method: 'post',
                mode: 'cors',
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    landingPageId: landingPageId
                })
            })
                .then((response: any) => response.json())
                .then((response: any) => JSON.parse(response))
                .then((response) => {
                    if (!response.result) {
                        subscriber.error({ result: false });
                        subscriber.complete();
                    }

                    const landingPage = response;
                    this.landingPageId = landingPageId;

                    subscriber.next(landingPage);
                    subscriber.complete();
                })
                .catch((error) => {
                    console.error(error);
                    subscriber.error({ result: false });
                    subscriber.complete();
                });
        });
    }
}
