import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './competencies.component.html',
    styleUrls: ['./competencies.component.css']
})
export class CompetenciesComponent implements OnInit {

    fragment: string = '';

    constructor(
        private route: ActivatedRoute,
        private titleService: Title,
        private router: Router,
    ) {}

    ngOnInit() {
        this.titleService.setTitle('ADIVEX - Kompetenzen');

        this.jumpToSection(this.router.url);

        this.router.events.subscribe((val) => {
            if (!(val instanceof NavigationEnd)) {
                return;
            }

            this.jumpToSection(this.router.url);
        });
    }

    jumpToSection(url: string): void {
        if (url.indexOf('kompetenzen') === -1) {
            window.scroll({
                left: 0,
                top: 0,
                behavior: 'smooth'
            });
            return;
        }

        const fragments: string[] = url.split('/').filter(v => !!v);
        if (fragments.length < 2) {
            window.scroll({
                left: 0,
                top: 0,
                behavior: 'smooth'
            });
            return;
        }

        window.scroll({
            left: 0,
            top: 0,
        });

        const $elem: Element = document.querySelector('#item-' + fragments[fragments.length - 1]);
        $elem.scrollIntoView(true);

        const scrolledY = window.scrollY;
        if (scrolledY) {
            setTimeout(() => {
                window.scroll({
                    left: 0,
                    top: scrolledY - 100,
                    behavior: 'smooth'
                });
            }, 50);
        }
    }
}
