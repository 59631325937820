import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { LandingPageComponent } from './landingpage.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        LandingPageComponent
    ],
    imports: [
        BrowserModule,
        RouterModule
    ],
    entryComponents: [
        LandingPageComponent
    ],
    providers: [],
    bootstrap: [LandingPageComponent]
})
export class LandingPageModule { }
