import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
    selector: 'app-root',
    template: `
        <h2 *ngIf="type === 'info'" mat-dialog-title>{{ title }}</h2>
        <h2 *ngIf="type === 'warning'" class="orange-color" mat-dialog-title>{{ title }}</h2>
        <h2 *ngIf="type === 'error'" class="red-color" mat-dialog-title>{{ title }}</h2>

        <mat-dialog-content>
            <span>{{ message }}</span>
            <br />
            <br />
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button class="mat-raised-button mat-primary"(click)="close()">OK</button>
        </mat-dialog-actions>
    `,
    styles: ['']
})
export class MessageDialogComponent implements OnInit {

    title: string = '';
    message: string = '';
    type: string = '';

    constructor(
        private dialogRef: MatDialogRef<MessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.title = data.title;
        this.message = data.message;
        this.type = data.type;
    }

    ngOnInit() {

    }

    close(): void {
        this.dialogRef.close();
    }
}